export default {
  downloads: [
    {
      text: 'All Plans',
      file: 'downloads/floorplans/All_Plans.zip',
    },
  ],
  floorplans: [

     {
      unit: 'D3',
      bed: 2,
      bath: 2,
      den: true,
      interior: '1,276',
    },
    {
      unit: 'D2',
      bed: 2,
      bath: 2,
      den: true,
      interior: '1,249',
    },
    {
      unit: 'C4',
      bed: 2,
      bath: 2,
      interior: '1,194',
    },{
      unit: 'C3',
      bed: 2,
      bath: 2,
      interior: '1,109',
    },{
      unit: 'C2',
      bed: 2,
      bath: 2,
      interior: '1,081',
    },{
      unit: 'C1',
      bed: 2,
      bath: 2,
      interior: '968',
    },



  ],
};
