import React from 'react';
import { useState, useRef } from 'react';
import styled from 'styled-components';

import Player from './Player';
import ButtonSection from './ButtonSection';
import ButtonPlay from 'src/components/Buttons/ButtonPlay';
import MobileOverlay from './MobileOverlay';

import vw from 'src/styles/utils';

const Video = ({ multiple, data }) => {
  const [playing, setPlaying] = useState(false);
  const [overlayActive, setOverlayActive] = useState(false);

  // create a ref to store the player
  const player = useRef();

  const handleClick = () => {
    if (player.current) {
      player.current.play();
      setPlaying(true);
    }
  };

  return (
    <Root multiple={multiple}>
      <ButtonSection
        link={data.download}
        overlayActive={overlayActive}
        setOverlayActive={setOverlayActive}
        shareLink={data.source}
      />
      <MobileOverlay
        link={data.download}
        overlayActive={overlayActive}
        setOverlayActive={setOverlayActive}
      />
      <Wrapper>
        <Player
          setPlaying={setPlaying}
          playing={playing}
          ref={player}
          poster={data.thumbnail}
          url={data.source}
        />
        <ButtonPlay onClick={handleClick} playing={playing} />
      </Wrapper>
    </Root>
  );
};

export default Video;

const Root = styled.div`
  position: relative;
  ${(props) =>
    props.multiple
      ? vw('width', 320 * 0.875, 608 * 0.95, 700)
      : vw('width', 320 * 0.875, 608 * 0.95, 1440)}

  ${(props) =>
    props.multiple
      ? vw('height', 178 * 0.875, 340 * 0.95, 393)
      : vw('height', 178 * 0.875, 340 * 0.95, 808)}
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
