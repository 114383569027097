import React from 'react';
import styled from 'styled-components';

import IconButton from 'src/components/IconButton';
import vw, { vwMobile, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import { Section } from 'src/styles/Section.styled';
import { SectionTitle } from 'src/styles/SectionTitle.styled';
import floorplates from '../../../data/floorplates';
import FloorplateWrapper from '../../../components/Floorplate/FloorplateWrapper';

const Floorplates = (props) => {
  return (
    <Section black>
      <CustomSectionTitle>Floorplans</CustomSectionTitle>
      <ButtonWrapper>
        <IconButton
          lightBtn
          href={`/downloads/floorplates/Arris-Floorplates.pdf`}
        >
          Download All Floorplates
        </IconButton>
      </ButtonWrapper>

      <SectionWrapper>
        {floorplates.map((floorplate, index) => (
          <FloorplateWrapper
            key={index}
            image={floorplate.image}
            title={floorplate.title}
          />
        ))}
        {/* <GalleryWrapper>
          {content.floorplans.map((floorplan, index) => (
            <FloorplanWrapper
              key={index}
              townhome={floorplan.townhome}
              bed={floorplan.bed}
              bath={floorplan.bath}
              extra={floorplan.extra}
              unit={floorplan.unit}
              fileName={floorplan.fileName}
              image={floorplan.image}
              interior={floorplan.interior}
              exterior={floorplan.exterior}
            />
          ))}
        </GalleryWrapper> */}
      </SectionWrapper>
    </Section>
  );
};

Floorplates.propTypes = {};

export default Floorplates;

const CustomSectionTitle = styled(SectionTitle)`
  /* ${vw('top', -20, 208, 325)}
  ${vw('left', -200, -105, -80)} */
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* align-items: flex-start; */
  /* ${vw('margin-top', 120)} */
  ${vw('margin-bottom', 40, 60, 120)}
  @media ${media.tablet} {
    /* justify-content: flex-end; */
    justify-content: space-between;
  }

  @media ${media.desktop} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${vw('gap', 40, 60, 120)}
  & + & {
    ${vw('margin-top', 40, 80, 120)}
  }
`;
