import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import media from 'src/styles/media';
import vw from 'src/styles/utils';
import FloorplanImage from './FloorplanImage';
import FloorplanSize from './FloorplanSize';

// import { FloorplanText } from '../../styles/FloorplanText.styled';
import FloorplanDetails from './FloorplanDetails';

const FloorplanWrapper = (props) => {
  const [expanded, setExpended] = React.useState(false);

  const {
    image,
    interior,
    exterior,
    fileName,
    extra,
    unit,
    bed,
    bath,
    townhome,
    tour,
    media,
  } = props;

  const handleClick = () => {
    setExpended(!expanded);
  };

  return (
    <Rooter>
      <Root>
        <FloorplanDetails
          townhome={townhome}
          extra={extra}
          unit={unit}
          bed={bed}
          bath={bath}
          media={media}
        />
        <MobileToggler
          expanded={expanded && 1}
          onClick={handleClick}
          src={require('src/assets/images/icons/open-close.svg')}
        />
        <Wrapper />
        <HideOnMobile expanded={expanded && 1}>
          <FloorplanImage image={image} />
          <FloorplanSize interior={interior} exterior={exterior} />
        </HideOnMobile>
      </Root>
      <DownloadWrapper
        as={'a'}
        href={tour ? fileName : `/${fileName}`}
        download={tour ? false : true}
        target={tour ? '_blank' : '_self'}
        tour={tour}
        data-unit={unit}
        data-bed={bed}
      >
        <DownloadText>{tour ? 'Launch Tour' : 'Download'}</DownloadText>
        {tour ? (
          <svg
            width="15"
            height="15"
            viewBox="0 0 15 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="download_icon">
              <g id="arrow">
                <g id="Union">
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.4315 8.17226L0.996719 8.17226V7.24654L12.4319 7.24654L7.69013 1.86503L8.26689 1.21045L13.993 7.70917L8.26689 14.2079L7.69013 13.5533L12.4315 8.17226Z"
                    fill="#262262"
                  />
                  <path
                    d="M12.4315 8.17226L12.8066 8.50281L13.5384 7.67226H12.4315V8.17226ZM0.996719 8.17226H0.496719V8.67226H0.996719L0.996719 8.17226ZM0.996719 7.24654L0.996719 6.74654H0.496719V7.24654H0.996719ZM12.4319 7.24654V7.74654H13.5388L12.807 6.91599L12.4319 7.24654ZM7.69013 1.86503L7.31498 1.53448L7.02373 1.86503L7.31498 2.19558L7.69013 1.86503ZM8.26689 1.21045L8.64204 0.879899L8.26689 0.454135L7.89174 0.879899L8.26689 1.21045ZM13.993 7.70917L14.3682 8.03972L14.6594 7.70917L14.3682 7.37862L13.993 7.70917ZM8.26689 14.2079L7.89174 14.5384L8.26689 14.9642L8.64204 14.5384L8.26689 14.2079ZM7.69013 13.5533L7.31498 13.2228L7.02373 13.5533L7.31498 13.8839L7.69013 13.5533ZM12.4315 7.67226L0.996719 7.67226L0.996719 8.67226L12.4315 8.67226V7.67226ZM1.49672 8.17226V7.24654H0.496719V8.17226H1.49672ZM0.996719 7.74654L12.4319 7.74654V6.74654L0.996719 6.74654L0.996719 7.74654ZM12.807 6.91599L8.06528 1.53448L7.31498 2.19558L12.0567 7.57709L12.807 6.91599ZM8.06528 2.19558L8.64204 1.541L7.89174 0.879899L7.31498 1.53448L8.06528 2.19558ZM7.89174 1.541L13.6179 8.03972L14.3682 7.37862L8.64204 0.879899L7.89174 1.541ZM13.6179 7.37862L7.89174 13.8773L8.64204 14.5384L14.3682 8.03972L13.6179 7.37862ZM8.64204 13.8773L8.06528 13.2228L7.31498 13.8839L7.89174 14.5384L8.64204 13.8773ZM8.06528 13.8839L12.8066 8.50281L12.0563 7.84171L7.31498 13.2228L8.06528 13.8839Z"
                    fill="#262262"
                  />
                </g>
              </g>
            </g>
          </svg>
        ) : (
          <svg
            width="15"
            height="21"
            viewBox="0 0 15 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.03522 12.6465L7.03522 1.21094L7.96094 1.21094L7.96094 12.6453L13.3415 7.90435L13.9961 8.48111L7.49737 14.2072L0.998656 8.48111L1.65323 7.90435L7.03522 12.6465Z"
              fill="#333132"
            />
            <path
              d="M7.03522 12.6465L6.70467 13.0217L7.53522 13.7535L7.53522 12.6465L7.03522 12.6465ZM7.03522 1.21094L7.03522 0.710937L6.53522 0.710937L6.53522 1.21094L7.03522 1.21094ZM7.96094 1.21094L8.46094 1.21094L8.46094 0.710937L7.96094 0.710937L7.96094 1.21094ZM7.96094 12.6453L7.46094 12.6453L7.46094 13.7522L8.29149 13.0204L7.96094 12.6453ZM13.3415 7.90435L13.6721 7.5292L13.3415 7.23795L13.011 7.5292L13.3415 7.90435ZM13.9961 8.48111L14.3266 8.85626L14.7524 8.48111L14.3266 8.10596L13.9961 8.48111ZM7.49737 14.2072L7.16682 14.5824L7.49737 14.8737L7.82793 14.5824L7.49737 14.2072ZM0.998656 8.48111L0.668106 8.10596L0.242342 8.48111L0.668106 8.85626L0.998656 8.48111ZM1.65323 7.90435L1.98378 7.5292L1.65323 7.23795L1.32268 7.5292L1.65323 7.90435ZM7.53522 12.6465L7.53522 1.21094L6.53522 1.21094L6.53522 12.6465L7.53522 12.6465ZM7.03522 1.71094L7.96094 1.71094L7.96094 0.710937L7.03522 0.710937L7.03522 1.71094ZM7.46094 1.21094L7.46094 12.6453L8.46094 12.6453L8.46094 1.21094L7.46094 1.21094ZM8.29149 13.0204L13.6721 8.2795L13.011 7.5292L7.63039 12.2701L8.29149 13.0204ZM13.011 8.2795L13.6655 8.85626L14.3266 8.10596L13.6721 7.5292L13.011 8.2795ZM13.6655 8.10596L7.16682 13.8321L7.82793 14.5824L14.3266 8.85626L13.6655 8.10596ZM7.82793 13.8321L1.32921 8.10596L0.668106 8.85626L7.16682 14.5824L7.82793 13.8321ZM1.32921 8.85626L1.98378 8.2795L1.32268 7.5292L0.668106 8.10596L1.32921 8.85626ZM1.32268 8.2795L6.70467 13.0217L7.36577 12.2714L1.98378 7.5292L1.32268 8.2795Z"
              fill="#333132"
            />
            <rect
              x="1.99609"
              y="18.7881"
              width="11"
              height="2"
              fill="#333132"
            />
          </svg>
        )}
      </DownloadWrapper>
    </Rooter>
  );
};

FloorplanWrapper.propTypes = {
  image: PropTypes.string,
  interior: PropTypes.string,
  exterior: PropTypes.number,
};

export default FloorplanWrapper;

const Rooter = styled.div`
  border: 2px solid ${(props) => props.theme.color.black};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
`;

const Root = styled.div`
  position: relative;

  ${vw('width', 280, 280, 348)}
  ${vw('padding-left', 16.09, 16.09, 20)}
  ${vw('padding-right', 16.09, 16.09, 20)}
  ${vw('padding-top', 16.12, 16.12, 20)}
  background-color: ${(props) => props.theme.color.white};

  ${Rooter}:hover & {
    @media ${media.desktop} {
      background-color: ${(props) => props.theme.color.black};
    }
  }
`;

const HideOnMobile = styled.div`
  display: ${(props) => (props.expanded ? 'block' : 'none')};

  @media ${media.tablet} {
    display: block;
  }
`;

const DownloadWrapper = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.color.grey};
  color: ${(props) => props.theme.color.white};

  path,
  rect {
    fill: ${(props) => props.theme.color.white};
  }

  svg {
    ${vw('width', 6, 13, 13)}
    ${vw('height', 9, 20, 19.58)}
  }

  @media ${media.tablet} {
    background-color: unset;
    color: ${(props) => props.theme.color.black};
    background-color: ${(props) => props.theme.color.white};
    path,
    rect {
      fill: ${(props) => props.theme.color.grey};
    }
  }

  @media ${media.desktop} {
    background-color: unset;
    color: ${(props) => props.theme.color.black};
    background-color: ${(props) => props.theme.color.white};
    &:hover {
      cursor: pointer;
    }
    ${Rooter}:hover & {
      color: ${(props) => props.theme.color.white};
      background-color: ${(props) => props.theme.color.black};
      path,
      rect {
        fill: ${(props) => props.theme.color.white};
      }
    }
  }
`;

const DownloadText = styled.span`
  ${vw('margin-right', 6, 10, 13)}
  text-transform: uppercase;
  font-family: 'azo-sans-web';
  ${vw('margin-top', -10, -15)}
  font-weight: 400;
  ${vw('font-size', 12, 18, 18)}
  ${vw('line-height', 40, 60, 60)}
  ${vw('letter-spacing', 0.96, 1.92, 1.92)}
`;

const MobileToggler = styled.img`
  position: absolute;
  svg {
    ${vw('width', 18)}
    ${vw('height', 18)}
  }
  ${vw('top', 26)}
  ${vw('right', 16.27)}
  transition: all 0.3s ease-in-out;
  transform: ${(props) => (props.expanded ? 'rotate(-45deg)' : 'rotate(0deg)')};
  @media ${media.tablet} {
    display: none;
  }
`;

const Wrapper = styled.div`
  ${vw('height', 20, 0, 0)}
`;
