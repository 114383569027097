import React from 'react';
import styled from 'styled-components';

import ButtonDownload from 'src/components/Buttons/ButtonDownload';
import ButtonMenu from 'src/components/Buttons/ButtonMenu';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const DesktopOverlay = (props) => {
  const { link, shareLink, overlayActive, setOverlayActive } = props;

  return (
    <Root>
      <Desktop>
        <ButtonDownload link={link} />
      </Desktop>
      <Mobile>
        <ButtonMenu
          onClick={() => {
            setOverlayActive(!overlayActive);
          }}
        />
      </Mobile>
    </Root>
  );
};

export default DesktopOverlay;

const Root = styled.div`
  position: absolute;
  ${vw('top', 0, 20, 20)}
  ${vw('right', 0, 20, 20)}
  z-index: 2;
`;

const Desktop = styled.div`
  display: none;

  a {
    ${vw('margin-right', 0, 12, 12)}
  }
  @media ${media.tablet} {
    display: flex;
  }
`;

const Mobile = styled.div`
  display: flex;

  @media ${media.tablet} {
    display: none;
  }
`;
