import styled from 'styled-components';
import vw from 'src/styles/utils';
import media from 'src/styles/media';

export const Button = styled.button`
  ${vw('padding-left', 12, 30, 30)}
  ${vw('padding-right', 12, 30, 30)}
  ${vw('height', 40, 60, 60)}

  transition: 0.5s ease;

  text-transform: uppercase;

  border: 2px solid
    ${({ theme, lightBtn, darkBtn }) =>
      darkBtn
        ? theme.color.black
        : lightBtn
        ? theme.color.offWhite
        : theme.color.offWhite};
  color: ${({ theme, darkBtn, lightBtn }) =>
    darkBtn
      ? theme.color.black
      : lightBtn
      ? theme.color.offWhite
      : theme.color.offWhite};
  path,
  rect {
    transition: 0.5s ease;
    fill: ${({ theme, lightBtn, darkBtn }) =>
      darkBtn
        ? theme.color.black
        : lightBtn
        ? theme.color.offWhite
        : theme.color.offWhite};
  }
  font-weight: 400;
  font-family: 'azo-sans-web';

  ${vw('font-size', 12, 18, 18)}
  ${vw('line-height', 40, 60, 60)}
  ${vw('letter-spacing', 0.96, 1.92, 1.92)}

  span {
    ${vw('margin-right', 6, 20, 20)}
  }

  @media ${media.tablet} {
    font-weight: regular;
  }

  @media ${media.desktop} {
    &:hover {
      cursor: pointer;
      background-color: ${({ theme, lightBtn, darkBtn }) =>
        darkBtn
          ? theme.color.black
          : lightBtn
          ? theme.color.offWhite
          : theme.color.offWhite};
      color: ${({ theme, lightBtn, darkBtn }) =>
        darkBtn
          ? theme.color.offWhite
          : lightBtn
          ? theme.color.grey
          : theme.color.grey};
      path,
      rect {
        fill: ${({ theme, lightBtn, darkBtn }) =>
          darkBtn
            ? theme.color.offWhite
            : lightBtn
            ? theme.color.grey
            : theme.color.grey};
      }
      border: 2px solid
        ${({ theme, lightBtn, darkBtn }) =>
          darkBtn
            ? theme.color.grey
            : lightBtn
            ? theme.color.offWhite
            : theme.color.offWhite};
    }
  }
`;
