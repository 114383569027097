import React from 'react';
// import PropTypes from 'prop-types'
import styled from 'styled-components';

import content from 'src/data/floorplans';

import IconButton from 'src/components/IconButton';
import FloorplanWrapper from 'src/components/Floorplan/FloorplanWrapper';
import vw, { vwMobile, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import { Section } from 'src/styles/Section.styled';
import { SectionTitle } from 'src/styles/SectionTitle.styled';

const Floorplans = (props) => {
  return (
    <Section black>
      <CustomSectionTitle>Floorplans</CustomSectionTitle>
      <ButtonWrapper>
        <div></div>
        <MicroWrapper>
          {content.downloads.map((download, index) => (
            <SubIconButton lightBtn key={index} href={`/${download.file}`}>
              {download.text}
            </SubIconButton>
          ))}
        </MicroWrapper>
      </ButtonWrapper>

      <SectionWrapper>
        <GalleryWrapper>
          {content.floorplans.map((floorplan, index) => (
            <FloorplanWrapper
              key={index}
              townhome={floorplan.townhome}
              bed={floorplan.bed}
              bath={floorplan.bath}
              extra={floorplan.den}
              media={floorplan.media}
              unit={floorplan.unit}
              fileName={`downloads/floorplans/Arris Floorplans_F_${floorplan.unit}.pdf`}
              image={require(`src/assets/images/arris/floorplans/${floorplan.unit}.png`)}
              interior={floorplan.interior}
              exterior={floorplan.exterior}
            />
          ))}
        </GalleryWrapper>
      </SectionWrapper>
    </Section>
  );
};

Floorplans.propTypes = {};

export default Floorplans;

const CustomSectionTitle = styled(SectionTitle)`
  /* ${vw('top', -20, 208, 325)}
  ${vw('left', -200, -105, -130)} */
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* align-items: flex-start; */
  /* ${vw('margin-top', 120)} */
  ${vw('margin-bottom', 40, 60, 120)}
  @media ${media.tablet} {
    /* justify-content: flex-end; */
    justify-content: space-between;
  }

  @media ${media.desktop} {
    flex-direction: row;
    align-items: flex-start;
  }
`;

const SubIconButton = styled(IconButton)``;

const MicroWrapper = styled.div`
  ${vw('margin-top', 40, 60, 0)}

  & > * {
    ${vw('margin-left', 0, 0, 15)}
    ${vw('margin-right', 14.5, 36.4, 15)}
    ${vw('margin-bottom', 15, 20, 15)}
  }

  & > * {
    /* margin-right: ${vwMobile(10)}; */
    /* margin-bottom: ${vwMobile(10)}; */
    /* ${vw('margin-left', 10, 40, 15)} */
  }
  @media ${media.desktop} {
    & > * + * {
      margin-left: ${vwDesktop(15)};
    }
  }
`;

const GalleryWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${vw('column-gap', 14.5, 6, 11)}
  ${vw('row-gap', 15, 20, 15)}
`;

const SectionWrapper = styled.div`
  & + & {
    ${vw('margin-top', 40, 80, 120)}
  }
`;
