import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

import Hero from './sections/Hero';
import Marketing from './sections/Marketing';
import News from './sections/News';
import Gallery from './sections/Gallery';
import Floorplans from './sections/Floorplans';
import VirtualTours from './sections/VirtualTours';
import Floorplates from './sections/Floorplates';
import Footer from './sections/footer/Footer';
import Header from './elements/Header';

import appConfig from 'src/config/app.conf';
import GuidedVideoTours from './sections/GuidedVideoTours/GuidedVideoTours';
import Amenities from './sections/Amenities';
import MarketingMaterial from './sections/MarketingMaterial';

const Home = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [passedTheElement, setPassedTheElement] = useState(false);
  const $stickyContainer = useRef();

  useEffect(() => {
    if (sessionStorage.getItem('password') === appConfig.password) {
      setLoggedIn(true);
    }
  }, []);

  // scroll header fixed
  useEffect(() => {
    // shows and hides the header when top image background touches the top:0
    const handleScroll = (e) => {
      if (
        e.target.scrollingElement.scrollTop >=
        $stickyContainer.current.offsetTop
      ) {
        setPassedTheElement(true);
        console.log('passed the element');
      } else {
        setPassedTheElement(false);
        console.log('not passed the element');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleChange = (e) => {
    sessionStorage.setItem('password', e.target.value);
    if (sessionStorage.getItem('password') === appConfig.password) {
      setLoggedIn(true);
    }
  };

  return (
    <Root>
      <Hero loggedIn={loggedIn} handleChange={handleChange} />
      {loggedIn && (
        <Content ref={$stickyContainer}>
          <Marketing />
          {/* <Floorplates /> */}
          <Floorplans />
          <VirtualTours />
          <Amenities />
          {/* <GuidedVideoTours /> */}
          {/* <Gallery /> */}
          <MarketingMaterial />
          <News />
        </Content>
      )}
      <Footer />
    </Root>
  );
};

export default Home;

const Root = styled.div`
  position: relative;
`;

const Content = styled.div``;
