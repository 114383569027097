import styled from 'styled-components';
import vw from 'src/styles/utils';

export const Section = styled.div`
  position: relative;
  ${vw('padding-right', 20, 40, 80)}
  ${vw('padding-left', 20, 148, 218)}
  ${vw('padding-bottom', 40, 80, 120)}
  ${vw('padding-top', 40, 80, 120)}
  
  background-color: ${({ theme, grey, black, white }) =>
    grey
      ? theme.color.grey
      : black
      ? theme.color.black
      : white
      ? theme.color.white
      : theme.color.black};
`;
