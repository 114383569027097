import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { SubHeader } from '../../styles/SubHeader.styled';

import vw from 'src/styles/utils';

const FloorplateWrapper = ({ title, image }) => {
  return (
    <Root>
      <SubHeader>{title}</SubHeader>
      <Wrapper>
        <img src={image} alt={title} />
      </Wrapper>
    </Root>
  );
};

FloorplateWrapper.propTypes = {
  className: PropTypes.string,
};

export default FloorplateWrapper;

const Root = styled.div``;

const Wrapper = styled.div`
  img {
    ${vw('margin-top', 40)}
    ${vw('width', 280, 580, '100%')}
  }
`;
