import React from 'react';
import styled from 'styled-components';

import IconButton from 'src/components/IconButton';
import vw, { vwMobile, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import { Section } from 'src/styles/Section.styled';
import { SectionTitle } from 'src/styles/SectionTitle.styled';
import floorplates from '../../../data/floorplates';
import FloorplateWrapper from '../../../components/Floorplate/FloorplateWrapper';

const Amenities = (props) => {
  return (
    <Section black>
      <CustomSectionTitle>Amenities</CustomSectionTitle>
      <ButtonWrapper>
        <IconButton
          lightBtn
          href={`/downloads/amenities/Arris_CLUBInsert_2023_F.pdf`}
        >
          Download Amenities Details
        </IconButton>
      </ButtonWrapper>

      <SectionWrapper>
        {/* {floorplates.map((floorplate, index) => (
          <FloorplateWrapper
            key={index}
            image={floorplate.image}
            title={floorplate.title}
          />
        ))} */}
        <iframe
          src="https://my.matterport.com/show/?m=fxkDEFTmf41"
          frameborder="0"
        ></iframe>
      </SectionWrapper>
    </Section>
  );
};

Amenities.propTypes = {};

export default Amenities;

const CustomSectionTitle = styled(SectionTitle)`
  /* ${vw('top', -20, 208, 325)}
  ${vw('left', -200, -105, -80)} */
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* align-items: flex-start; */
  /* ${vw('margin-top', 120)} */
  ${vw('margin-bottom', 40, 60, 120)}
  @media ${media.tablet} {
    /* justify-content: flex-end; */
    justify-content: space-between;
  }

  @media ${media.desktop} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${vw('gap', 40, 60, 120)}
  & + & {
    ${vw('margin-top', 40, 80, 120)}
  }

  iframe {
    ${vw('height', 175, 400, 900)}
  }
`;
