import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconButton from 'src/components/IconButton';
import Tags from 'src/components/Tags';

import content from 'src/data/marketing.js';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

import { Section } from 'src/styles/Section.styled';
import { SectionTitle } from 'src/styles/SectionTitle.styled';
import { SubHeader } from 'src/styles/SubHeader.styled';
import { Body } from 'src/styles/Body.styled';

const News = (props) => {
  return (
    <Section black>
      <CustomSectionTitle>In the News</CustomSectionTitle>
      {/* 6  */}
      <Wrapper>
        <Piece first>
          <StyledSubHeader bolder>CTV News - June 27, 2024</StyledSubHeader>
          <ExternalLink
            large
            as="a"
            href={
              '/downloads/news/Calgary fifth most livable city in the world_ report _ CTV News.pdf'
            }
            target="_blank"
          >
            Calgary is the fifth most livable city in the world
          </ExternalLink>
        </Piece>
        <Piece>
          <StyledSubHeader bolder>
            Calgary Herald - June 19, 2024
          </StyledSubHeader>

          <ExternalLink
            large
            as="a"
            href={`/downloads/news/Alberta continues to see largest population growth in Canada _ Calgary Herald.pdf`}
            target="_blank"
          >
            As migrants continue to arrive, Alberta still seeing
            largestpopulation growth in Canada
          </ExternalLink>
        </Piece>
        <Piece>
          <StyledSubHeader bolder>CTV News - June 19, 2024</StyledSubHeader>
          <ExternalLink
            large
            as="a"
            href={`/downloads/news/Alberta’s population grows by nearly 50K_ StatCan _ CTV News.pdf`}
            target="_blank"
          >
            Alberta's population grows by nearly 50K, fuelled by interprovincial
            migration
          </ExternalLink>
        </Piece>
        <Piece>
          <StyledSubHeader bolder>
            Calgary Herald - August 20, 2024
          </StyledSubHeader>
          <ExternalLink
            large
            as="a"
            href={`/downloads/news/Investment red hot in Calgary's rental real-estate market _ Calgary Herald.pdf`}
            target="_blank"
          >
            Investment red hot in Calgary's rental real-estate market
          </ExternalLink>
        </Piece>
        <Piece></Piece>
      </Wrapper>
    </Section>
  );
};

News.propTypes = {};

export default News;

const CustomSectionTitle = styled(SectionTitle)`
  /* ${vw('top', -20, 208, 325)}
  ${vw('left', -200, -105, -80)} */
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${media.desktop} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  color: ${(props) => props.theme.color.black};
`;

const StyledSubHeader = styled(SubHeader)`
  ${vw('margin-bottom', 10, 20, 20)}

  ${({ bolder }) => bolder && 'font-weight: 600;'}
`;

const Piece = styled.div`
  flex: 50%;
  ${(props) => props.first && vw('margin-bottom', 30, 60, 70)}
  & + & {
    ${vw('margin-bottom', 40, 60, 70)}
  }
`;

const ExternalLink = styled(Body)`
  text-decoration: underline;
  transition: 0.5s ease;

  line-height: 140% !important;

  @media ${media.desktop} {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const List = styled.div`
  color: ${({ theme }) => theme.color.black};

  ${vw('width', 280, 476, 476)}

  ul {
    ${vw('margin-top', 40)}
    ${vw('padding-left', 40)}
    display: flex;
    flex-direction: column;
    ${vw('gap', 40)}

    li {
      ${vw('font-size', 16, 21, 21)}
    }
  }
`;
