import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { FloorplanText } from '../../styles/FloorplanText.styled';

import media from '../../styles/media';
import vw from '../../styles/utils';

const FloorplanDetails = (props) => {
  const { extra, unit, bed, bath, media } = props;
  return (
    <DetailsRooter>
      <Root>
        <Unit>{unit}</Unit>
        <Details>
          <FloorplanText extra>{bed} BED</FloorplanText>
          <FloorplanText extra>{bath} BATH</FloorplanText>
          <FloorplanText extra={extra}>+ DEN</FloorplanText>
          <FloorplanText extra={media}>+ MEDIA</FloorplanText>
        </Details>
      </Root>
      <Space />
    </DetailsRooter>
  );
};

FloorplanDetails.propTypes = {
  unit: PropTypes.string,
  bed: PropTypes.number,
  bath: PropTypes.number,
  extra: PropTypes.bool,
  townhome: PropTypes.bool,
  media: PropTypes.bool,
};

export default FloorplanDetails;

const DetailsRooter = styled.div``;
const Space = styled.div`
  ${vw('height', 0, 0, 30)}
  background-color: ${({ theme }) => theme.color.white};
  font-family: 'azo-sans-web';
`;

const Root = styled.div`
  background-color: ${(props) => props.theme.color.white};
  ${vw('padding-left', 0, 0, 20)}
  ${vw('padding-right', 0, 0, 20)}
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: ${(props) => props.theme.color.black};

  @media ${media.tablet} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
`;

const Unit = styled.p`
  font-family: 'azo-sans-web';
  font-weight: 400;
  ${vw('margin-top', 10)}
  ${vw('font-size', 48, 48, 64)}
  ${vw('line-height', 48, 0, 0)}
  ${vw('letter-spacing', 0.96, 0.96, 0.96)}
  color: ${(props) => props.theme.color.black};
`;

const Details = styled.div`
  text-align: left;
  font-family: 'azo-sans-web';
  ${vw('margin-top', 20, 0, 0)}

  @media ${media.tablet} {
    text-align: right;
  }
`;

// const Extra = styled.div`
//   ${vw('padding-left', 0, 0, 20)}
//   ${vw('padding-right', 0, 0, 20)}
//   text-transform: uppercase;
//   text-align: left;
//   background-color: ${(props) => props.theme.color.white};
//   @media ${media.tablet} {
//     text-align: right;
//     height: ${vwTablet(48)};
//   }
//   @media ${media.desktop} {
//     height: ${vwDesktop(5)};
//   }
// `;
