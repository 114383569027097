import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import Logo from './elements/Logo';
import Disclaimer from './elements/Disclaimer';
import Information from './elements/Information';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

import townline from 'src/assets/images/footer/townline_logo.png';

const Footer = (props) => {
  return (
    <Root>
      <Container>
        <Information />
        <Disclaimer />
      </Container>
    </Root>
  );
};

Footer.propTypes = {};

export default Footer;

const Root = styled.footer`
  ${vw('padding-bottom', 40, 40, 80)}
  ${vw('padding-left', 20, 40, 60)}
  ${vw('padding-right', 20, 40, 60)}
  ${vw('padding-top', 40, 80, 80)}
  background-color: ${({ theme }) => theme.color.black};
  display: flex;
`;

const Container = styled.div`
  ${vw('padding-left', 0, 58, 98)}
`;
