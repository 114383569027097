import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Tag from './Tag';

import vw from 'src/styles/utils';

const Tags = (props) => {
  const { data } = props;

  return (
    <Root>
      <List>
        {data.map((item, index) => {
          return <Tag key={index}>{item}</Tag>;
        })}
      </List>
    </Root>
  );
};

Tags.propTypes = {
  data: PropTypes.array,
};

export default Tags;

const Root = styled.div`
  ${vw('width', 280, 388, 388)}
`;
const List = styled.ul``;
