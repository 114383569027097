export default {
  icons: [
    {
      name: 'facebook',
      url: 'https://www.facebook.com/TownlineHomes/',
      icon: require('src/assets/images/footer/facebook.svg'),
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/townlinehomes/',
      icon: require('src/assets/images/footer/instagram.svg'),
    },
    {
      name: 'twitter',
      url: 'https://twitter.com/Townline_Homes/',
      icon: require('src/assets/images/footer/twitter.svg'),
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/user/TownlineHomes',
      icon: require('src/assets/images/footer/youtube.svg'),
    },
  ],
};
