export default {
  items: [
    {
      title: 'Exterior',
      images: [
        {
          image: require('src/assets/images/arris/gallery/Exterior/Exterior_1@2x.jpg'),
          file: 'Exterior/Exterior_1@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Exterior/Exterior_2@2x.jpg'),
          file: 'Exterior/Exterior_2@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Exterior/Exterior_3@2x.jpg'),
          file: 'Exterior/Exterior_3@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Exterior/Exterior_4@2x.jpg'),
          file: 'Exterior/Exterior_4@2x.jpg',
        },
      ],
    },
    {
      title: 'Interior',
      images: [
        {
          image: require('src/assets/images/arris/gallery/Interior/Interior_1@2x.jpg'),
          file: 'Interior/Interior_1@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Interior/Interior_2@2x.jpg'),
          file: 'Interior/Interior_2@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Interior/Interior_3@2x.jpg'),
          file: 'Interior/Interior_3@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Interior/Interior_4@2x.jpg'),
          file: 'Interior/Interior_4@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Interior/Interior_5@2x.jpg'),
          file: 'Interior/Interior_5@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Interior/Interior_6@2x.jpg'),
          file: 'Interior/Interior_6@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Interior/Interior_7@2x.jpg'),
          file: 'Interior/Interior_7@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Interior/Interior_8@2x.jpg'),
          file: 'Interior/Interior_8@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Interior/Interior_9@2x.jpg'),
          file: 'Interior/Interior_9@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Interior/Interior_10@2x.jpg'),
          file: 'Interior/Interior_10@2x.jpg',
        },
      ],
    },
    {
      title: 'Amenities',
      images: [
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_1@2x.jpg'),
          file: 'Amenities/Amenities_1@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_2@2x.jpg'),
          file: 'Amenities/Amenities_2@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_3@2x.jpg'),
          file: 'Amenities/Amenities_3@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_4@2x.jpg'),
          file: 'Amenities/Amenities_4@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_5@2x.jpg'),
          file: 'Amenities/Amenities_5@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_6@2x.jpg'),
          file: 'Amenities/Amenities_6@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_7@2x.jpg'),
          file: 'Amenities/Amenities_7@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_8@2x.jpg'),
          file: 'Amenities/Amenities_8@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_9@2x.jpg'),
          file: 'Amenities/Amenities_9@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_10@2x.jpg'),
          file: 'Amenities/Amenities_10@2x.jpg',
        },
      ],
    },
  ],
};
