import styled from 'styled-components';
import vw from 'src/styles/utils';

export const SubHeader = styled.p`
  font-weight: 400;
  font-family: 'azo-sans-web';
  text-transform: uppercase;
  color: ${({ theme, dark }) =>
    dark ? theme.color.black : theme.color.offWhite};
  ${({ bolder }) =>
    vw('font-size', bolder ? 16 : 12, bolder ? 22 : 16, bolder ? 18 : 14)}
  ${vw('line-height', 14, 24, 17)}
  ${vw('letter-spacing', 2.88, 3.84, 3.84)}
`;
