import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button } from 'src/styles/Button.styled';

import vw from 'src/styles/utils';

const IconButton = (props) => {
  const {
    lightBtn,
    darkBtn,
    children,
    marketing,
    href,
    socialmedia,
    newWindow,
  } = props;

  return (
    <StyledButton
      as={href ? 'a' : Button}
      href={href}
      download={href && true}
      marketing={marketing && 1}
      lightBtn={lightBtn && 1}
      socialmedia={socialmedia && 1}
      darkBtn={darkBtn && 1}
      target={'_self' && newWindow}
    >
      <Span>{children}</Span>
      <svg
        width="15"
        height="21"
        viewBox="0 0 15 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.03522 12.6465L7.03522 1.21094L7.96094 1.21094L7.96094 12.6453L13.3415 7.90435L13.9961 8.48111L7.49737 14.2072L0.998656 8.48111L1.65323 7.90435L7.03522 12.6465Z"
          fill="#333132"
        />
        <path
          d="M7.03522 12.6465L6.70467 13.0217L7.53522 13.7535L7.53522 12.6465L7.03522 12.6465ZM7.03522 1.21094L7.03522 0.710937L6.53522 0.710937L6.53522 1.21094L7.03522 1.21094ZM7.96094 1.21094L8.46094 1.21094L8.46094 0.710937L7.96094 0.710937L7.96094 1.21094ZM7.96094 12.6453L7.46094 12.6453L7.46094 13.7522L8.29149 13.0204L7.96094 12.6453ZM13.3415 7.90435L13.6721 7.5292L13.3415 7.23795L13.011 7.5292L13.3415 7.90435ZM13.9961 8.48111L14.3266 8.85626L14.7524 8.48111L14.3266 8.10596L13.9961 8.48111ZM7.49737 14.2072L7.16682 14.5824L7.49737 14.8737L7.82793 14.5824L7.49737 14.2072ZM0.998656 8.48111L0.668106 8.10596L0.242342 8.48111L0.668106 8.85626L0.998656 8.48111ZM1.65323 7.90435L1.98378 7.5292L1.65323 7.23795L1.32268 7.5292L1.65323 7.90435ZM7.53522 12.6465L7.53522 1.21094L6.53522 1.21094L6.53522 12.6465L7.53522 12.6465ZM7.03522 1.71094L7.96094 1.71094L7.96094 0.710937L7.03522 0.710937L7.03522 1.71094ZM7.46094 1.21094L7.46094 12.6453L8.46094 12.6453L8.46094 1.21094L7.46094 1.21094ZM8.29149 13.0204L13.6721 8.2795L13.011 7.5292L7.63039 12.2701L8.29149 13.0204ZM13.011 8.2795L13.6655 8.85626L14.3266 8.10596L13.6721 7.5292L13.011 8.2795ZM13.6655 8.10596L7.16682 13.8321L7.82793 14.5824L14.3266 8.85626L13.6655 8.10596ZM7.82793 13.8321L1.32921 8.10596L0.668106 8.85626L7.16682 14.5824L7.82793 13.8321ZM1.32921 8.85626L1.98378 8.2795L1.32268 7.5292L0.668106 8.10596L1.32921 8.85626ZM1.32268 8.2795L6.70467 13.0217L7.36577 12.2714L1.98378 7.5292L1.32268 8.2795Z"
          fill="#333132"
        />
        <rect x="1.99609" y="18.7881" width="11" height="2" fill="#333132" />
      </svg>
    </StyledButton>
  );
};

IconButton.propTypes = {
  lightBtn: PropTypes.bool,
  darkBtn: PropTypes.bool,
  newWindow: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default IconButton;

const StyledButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  /* ${(props) => props.marketing && vw('margin-top', 10, 20, 20)}; */

  font-family: 'azo-sans-web';

  svg {
    ${vw('width', 6, 13, 13)};
    ${vw('height', 9, 19.58, 19.58)};
  }

  path,
  rect {
    fill: ${(props) => (props.lightBtn ? 'white' : props.theme.color.black)};
  }
`;
const Span = styled.span`
  ${vw('margin-top', -15)}
`;
