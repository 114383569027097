import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';

const FloorplanImage = (props) => {
  const { image } = props;
  return (
    <Root>
      <Image src={image} />
    </Root>
  );
};

FloorplanImage.propTypes = {
  image: PropTypes.string,
};

export default FloorplanImage;

const Root = styled.div`
  background-color: ${(props) => props.theme.color.white};

  ${vw('width', 240, 240, 308)}
  ${vw('height', 200, 200, 251)}
  ${vw('padding-bottom', 12, 12, 15)}
  ${vw('padding-top', 12, 12, 12)}

  display: flex;
  align-items: center;
  justify-content: center;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
`;
