import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';

const ButtonPlay = ({ onClick, playing }) => {
  return (
    <Root playing={playing}>
      <StyledPlayIcon playing={playing} onClick={onClick}>
        <img src={theme.icons.play.default} alt="download icon" />
        <img src={theme.icons.play.hover} alt="download icon" />
      </StyledPlayIcon>
      <Background playing={playing} />
    </Root>
  );
};

export default ButtonPlay;

const Root = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ playing }) => (playing ? 0 : 1)};
  pointer-events: ${({ playing }) => (playing ? 'none' : 'all')};
  transition: ${({ theme }) => theme.transition};
`;

const StyledPlayIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  opacity: ${({ playing }) => (playing ? 0 : 1)};
  pointer-events: ${({ playing }) => (playing ? 'none' : 'all')};

  transition: ${({ theme }) => theme.transition};

  ${vw('width', 46.52, 69.78, 69.78)}
  ${vw('height', 46.52, 69.78, 69.78)}

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: ${(props) => props.theme.transition};

    &:first-child {
      opacity: 1;
    }

    &:last-child {
      opacity: 0;
    }
  }

  &:hover {
    cursor: pointer;
    transition: ${(props) => props.theme.transition};

    img {
      &:first-child {
        opacity: 0;
      }

      &:last-child {
        opacity: 1;
      }
    }
  }
`;

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: ${({ theme }) => theme.color.black};
  opacity: ${({ playing }) => (playing ? 0 : 0.2)};
  pointer-events: ${({ playing }) => (playing ? 'none' : 'all')};
  transition: ${({ theme }) => theme.transition};
`;
