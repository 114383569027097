import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

import { path } from 'src/config/app.conf';

const ImageFrame = (props) => {
  const { story, image, fileName, dark, special, title, thumbnail } = props;
  return (
    <Root as={'a'} href={`/downloads${fileName}`} download dark={dark}>
      {special ? (
        <SpecialFrame>
          <img src={thumbnail} alt="" />
        </SpecialFrame>
      ) : (
        <ImageWrapper src={image} story={story && 1} />
      )}
      <DownloadWrapper>
        <DownloadText dark={dark}>Download</DownloadText>
        <DownloadIcon>
          <svg
            width="15"
            height="21"
            viewBox="0 0 15 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.03522 12.6465L7.03522 1.21094L7.96094 1.21094L7.96094 12.6453L13.3415 7.90435L13.9961 8.48111L7.49737 14.2072L0.998656 8.48111L1.65323 7.90435L7.03522 12.6465Z"
              fill="#333132"
            />
            <path
              d="M7.03522 12.6465L6.70467 13.0217L7.53522 13.7535L7.53522 12.6465L7.03522 12.6465ZM7.03522 1.21094L7.03522 0.710937L6.53522 0.710937L6.53522 1.21094L7.03522 1.21094ZM7.96094 1.21094L8.46094 1.21094L8.46094 0.710937L7.96094 0.710937L7.96094 1.21094ZM7.96094 12.6453L7.46094 12.6453L7.46094 13.7522L8.29149 13.0204L7.96094 12.6453ZM13.3415 7.90435L13.6721 7.5292L13.3415 7.23795L13.011 7.5292L13.3415 7.90435ZM13.9961 8.48111L14.3266 8.85626L14.7524 8.48111L14.3266 8.10596L13.9961 8.48111ZM7.49737 14.2072L7.16682 14.5824L7.49737 14.8737L7.82793 14.5824L7.49737 14.2072ZM0.998656 8.48111L0.668106 8.10596L0.242342 8.48111L0.668106 8.85626L0.998656 8.48111ZM1.65323 7.90435L1.98378 7.5292L1.65323 7.23795L1.32268 7.5292L1.65323 7.90435ZM7.53522 12.6465L7.53522 1.21094L6.53522 1.21094L6.53522 12.6465L7.53522 12.6465ZM7.03522 1.71094L7.96094 1.71094L7.96094 0.710937L7.03522 0.710937L7.03522 1.71094ZM7.46094 1.21094L7.46094 12.6453L8.46094 12.6453L8.46094 1.21094L7.46094 1.21094ZM8.29149 13.0204L13.6721 8.2795L13.011 7.5292L7.63039 12.2701L8.29149 13.0204ZM13.011 8.2795L13.6655 8.85626L14.3266 8.10596L13.6721 7.5292L13.011 8.2795ZM13.6655 8.10596L7.16682 13.8321L7.82793 14.5824L14.3266 8.85626L13.6655 8.10596ZM7.82793 13.8321L1.32921 8.10596L0.668106 8.85626L7.16682 14.5824L7.82793 13.8321ZM1.32921 8.85626L1.98378 8.2795L1.32268 7.5292L0.668106 8.10596L1.32921 8.85626ZM1.32268 8.2795L6.70467 13.0217L7.36577 12.2714L1.98378 7.5292L1.32268 8.2795Z"
              fill="#333132"
            />
            <rect
              x="1.99609"
              y="18.7881"
              width="11"
              height="2"
              fill="#333132"
            />
          </svg>
        </DownloadIcon>
      </DownloadWrapper>
    </Root>
  );
};

ImageFrame.propTypes = {
  story: PropTypes.bool,
  image: PropTypes.bool,
};

export default ImageFrame;

const Root = styled.div`
  ${vw('width', 134, 180, 348)}
  /* ${(props) =>
    vw('height', 155, props.story ? 336 : 212, props.story ? 629 : 390)} */
  ${vw('padding-right', 0, 10, 20)}
  ${vw('padding-left', 0, 10, 20)}
  ${vw('padding-bottom', 10, 10, 20)}
  ${vw('padding-top', 0, 10, 20)}
  transition: 0.5s all ease;
  color: ${({ theme, dark }) =>
    dark ? theme.color.black : theme.color.offWhite};
  border: 1px solid ${({ theme }) => theme.color.black};
  background-color: ${({ theme, dark }) =>
    dark ? theme.color.white : theme.color.black};
  path,
  rect {
    transition: 0.5s ease;
    fill: ${({ theme }) => theme.color.black};
  }

  &:hover {
    cursor: pointer;
    background-color: ${({ theme, dark }) =>
      dark ? theme.color.black : theme.color.white};
    color: ${({ theme, dark }) =>
      dark ? theme.color.white : theme.color.black};
    path,
    rect {
      fill: ${({ theme }) => theme.color.white};
    }
  }
`;

const ImageWrapper = styled.img`
  object-fit: cover;
  object-position: center;
  ${vw('width', 134, 160, 308)}
  ${(props) =>
    props.story ? vw('height', 237, 284, 547) : vw('height', 134, 160, 308)}
  ${vw('margin-bottom', 5, 10, 20)}
`;

const DownloadWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  ${vw('padding-left', 5, 0, 'auto')}
  ${vw('padding-right', 10, 0, 'auto')}

  @media ${media.tablet} {
    justify-content: space-between;
    align-items: center;
  }

  @media ${media.desktop} {
    justify-content: flex-start;
  }
`;

const DownloadText = styled.span`
  text-transform: uppercase;
  font-family: 'azo-sans-web';
  ${vw('margin-top', -10, -15)}
  font-weight: 400;
  ${vw('font-size', 13, 18, 18)}
  ${vw('line-height', 16, 22, 22)}
  ${vw('letter-spacing', 0.96, 1.92, 1.92)}
  ${vw('margin-right', 10, 0, 14)}
`;

const DownloadIcon = styled.div`
  ${vw('padding-top', 3, 5, 0)}
  svg {
    ${vw('width', 10, 10, 13)}
  }
`;

const SpecialFrame = styled.div`
  ${vw('width', 134, 180, 308)}
  ${vw('height', 134, 160, 308)}
  background-color: ${({ theme }) => theme.color.black};
  ${vw('margin-bottom', 5, 10, 20)}

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .card-title {
    color: ${({ theme }) => theme.color.white};
    text-transform: uppercase;
  }
`;
