import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Section } from 'src/styles/Section.styled';
import { SectionTitle } from 'src/styles/SectionTitle.styled';

import vw from 'src/styles/utils';
import Videos from './elements/Videos';

const GuidedVideoTours = ({ className }) => {
  return (
    <Root>
      <Section black>
        <CustomSectionTitle>Guided Video Tours</CustomSectionTitle>
        <SectionWrapper>
          {/* {floorplates.map((floorplate, index) => (
            <FloorplateWrapper
              key={index}
              image={floorplate.image}
              title={floorplate.title}
            />
          ))} */}
          <Videos />
        </SectionWrapper>
      </Section>
    </Root>
  );
};

GuidedVideoTours.propTypes = {
  className: PropTypes.string,
};

export default GuidedVideoTours;

const Root = styled.div``;

const CustomSectionTitle = styled(SectionTitle)``;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${vw('gap', 40, 60, 40)}

  & + & {
    ${vw('margin-top', 40, 80, 120)}
  }
`;
