import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import IconButton from 'src/components/IconButton';
import Tags from 'src/components/Tags';

import content from 'src/data/marketing.js';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

import { Section } from 'src/styles/Section.styled';
import { SectionTitle } from 'src/styles/SectionTitle.styled';
import { SubHeader } from 'src/styles/SubHeader.styled';
import { Body } from 'src/styles/Body.styled';

const Marketing = (props) => {
  return (
    <Section white>
      <CustomSectionTitle dark>Sales Info</CustomSectionTitle>
      {/* 6  */}
      <Wrapper>
        <Piece first>
          <StyledSubHeader dark>Website</StyledSubHeader>
          <ExternalLink
            large
            as="a"
            href="https://arrisresidences.ca"
            target="_blank"
          >
            arrisresidences.ca
          </ExternalLink>
        </Piece>
        <Piece>
          <StyledSubHeader dark>Project Summary Info Pack</StyledSubHeader>
          <IconButton
            href={`/Project Summary Info Pack.pdf`}
            marketing
            darkBtn
          >
            Download
          </IconButton>
        </Piece>
        <Piece>
          <StyledSubHeader dark>Calgary Real Estate Board PPT</StyledSubHeader>
          <IconButton
            href={`/Calgary Real Estate Board Presentation_June 2024.pdf`}
            marketing
            darkBtn
          >
            Download
          </IconButton>
        </Piece>
        {/* <Piece>
          <StyledSubHeader dark>
          Preview Price List
          </StyledSubHeader>
          <IconButton
            href="https://portal.arrisresidences.ca/wWoRLzirlK/ListingReport.pdf"
            newWindow="_blank"
            marketing
            darkBtn
          >
            Download
          </IconButton>
        </Piece> */}
        <Piece>
          <StyledSubHeader dark>Project Feature Sheet</StyledSubHeader>
          <IconButton darkBtn href={`/Arris_Residences_Features.pdf`} marketing>
            Download
          </IconButton>
        </Piece>
        {/* <Piece>
          <StyledSubHeader dark>Worksheet Signing Procedure</StyledSubHeader>
          <IconButton
            href={`/Arris_Residences_-_Disclosure_Documents_(Final_-_July_18,_2023).pdf`}
            marketing
            darkBtn
          >
            Download
          </IconButton>
        </Piece> */}
        <Piece>
          <StyledSubHeader dark bolder>
            Why Arris Residences
          </StyledSubHeader>
          <List>
            <ul>
            <li>Buy from the most trusted developer in the Pacific Northwest – Bosa Development</li>
            <li>Partnered with RioCan, you have direct access to over 170,000 sf of retail without having to go outside! RioCan owned and operated</li>
            <li>Capitalize on the prime location along Calgary’s rivers’ edge, offering unmatched river views, accessibility, and convenience </li>
            <li>Benefit from Calgary’s most acclaimed building amenities and services, enhancing property value and tenant appeal </li>
            <li>Invest in the largest and most livable new homes in Calgary’s downtown core, ensuring strong demand and rental potential </li>

            </ul>
          </List>
        </Piece>
        <Piece>
          <StyledSubHeader dark bolder>
            Why Now
          </StyledSubHeader>
          <List>
            <ul>
            <li>Limited time VIP pricing for Toronto Only – Starting from $499/ft - below replacement costs!</li>
            <li>Positive Cash Flow investment from Day 1</li>
            <li>Suites appraising at higher than replacement value – instant gains</li>
            </ul>
          </List>
        </Piece>
      </Wrapper>
    </Section>
  );
};

Marketing.propTypes = {};

export default Marketing;

const CustomSectionTitle = styled(SectionTitle)`
  /* ${vw('top', -20, 208, 325)}
  ${vw('left', -200, -105, -80)} */
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media ${media.desktop} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  color: ${(props) => props.theme.color.black};
`;

const StyledSubHeader = styled(SubHeader)`
  ${vw('margin-bottom', 10, 20, 20)}

  ${({ bolder }) => bolder && 'font-weight: 600;'}
`;

const Piece = styled.div`
  flex: 50%;
  ${(props) => props.first && vw('margin-bottom', 30, 60, 70)}
  & + & {
    ${vw('margin-bottom', 40, 60, 70)}
  }
`;

const ExternalLink = styled(Body)`
  text-decoration: underline;
  transition: 0.5s ease;
  color: ${({ theme }) => theme.color.black};
  @media ${media.desktop} {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

const List = styled.div`
  color: ${({ theme }) => theme.color.black};

  ${vw('width', 280, 476, 476)}

  ul {
    ${vw('margin-top', 40)}
    ${vw('padding-left', 40)}
    display: flex;
    flex-direction: column;
    ${vw('gap', 40)}

    li {
      ${vw('font-size', 16, 21, 21)}
    }
  }
`;
