import React from 'react';

import styled from 'styled-components';

import { ReactComponent as Kebab } from 'src/assets/images/arris/icons/kebab_menu.svg';

import vw from 'src/styles/utils';

const ButtonMenu = ({ onClick }) => {
  return (
    <Root onClick={onClick}>
      <StyledKebab />
    </Root>
  );
};

export default ButtonMenu;

const Root = styled.div`
  cursor: pointer;
`;

const StyledKebab = styled(Kebab)`
  ${vw('width', 29)}
  ${vw('height', 29)}
`;
