export default [
  {
    title: 'FLOORPLATE (LEVEL 4 - 36)',
    image: require('src/assets/images/arris/plates/plate-4-36@1.5x.png'),
  },
  {
    title: 'FLOORPLATE (LEVEL 37 - 39)',
    image: require('src/assets/images/arris/plates/plate-37-39@1.5x.png'),
  },
  {
    title: 'FLOORPLATE (LEVEL 40 - 41)',
    image: require('src/assets/images/arris/plates/plate-40-41@1.5x.png'),
  },
];
