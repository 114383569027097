import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

const Logo = (props) => {
  return (
    <Root>
      <svg
        width="80"
        height="403"
        viewBox="0 0 80 403"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M57.9503 320.331C70.6866 320.331 80 328.208 80 348.497V403H0V348.656C0 329.322 10.5075 322.559 20.6169 322.559C29.0547 322.559 36.8557 327.253 38.607 337.199C40.4378 324.946 48.0796 320.331 57.9503 320.331ZM7.9602 393.531H35.1841V349.293C35.1841 334.096 27.6219 331.55 21.0149 331.55C14.9652 331.55 7.9602 335.051 7.9602 349.532V393.531ZM72.1194 348.975C72.1194 334.812 66.4677 329.402 57.7114 329.402C49.1144 329.402 43.0647 334.335 43.0647 348.736V393.531H72.1194V348.975Z"
          fill="black"
        />
        <path
          d="M79.9985 220.795L62.3269 230.343V287.63L79.9985 297.178V309.988L0.078125 265.193V252.86L79.9985 207.985V220.795ZM52.695 282.458V235.515L16.3965 255.247C14.4861 256.122 11.2224 257.873 8.99356 258.986V259.225C11.2224 260.339 14.4861 261.93 16.3965 262.965L52.695 282.458Z"
          fill="black"
        />
        <path
          d="M80 194.14H0V175.124L53.1741 124.838C55.801 122.451 58.6667 120.223 61.3731 118.95V118.632H50.8657H0V103.276H79.9204V120.621L25.6319 172.657C23.1642 175.044 19.9005 177.351 17.6717 178.386V178.863H27.5423H79.9204V194.14H80Z"
          fill="black"
        />
        <path
          d="M80 82.9074H0V43.2041C0 16.8679 14.9652 0 39.0846 0C63.5224 0 79.9204 17.3453 79.9204 43.4428V82.9074H80ZM65.4328 62.459V45.1137C65.4328 29.9962 54.3682 21.4032 39.3234 21.4032C24.3582 21.4032 14.5672 30.1554 14.5672 45.1137V62.459H65.4328Z"
          fill="black"
        />
      </svg>
    </Root>
  );
};

Logo.propTypes = {};

export default Logo;

const Root = styled.div`
  display: none;
  svg {
    ${vw('width', 0, 50, 60)}
    ${vw('height', 0, 251, 301)}
  }
  path {
    fill: ${({ theme }) => theme.color.offWhite};
  }

  @media ${media.tablet} {
    display: block;
  }
`;
