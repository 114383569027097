import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import content from 'src/data/footer';

import vw from 'src/styles/utils';

const Social = (props) => {
  return (
    <Root>
      {content.icons.map((icon, index) => (
        <SocialIcon href={icon.url} target="_blank" key={index}>
          <img src={icon.icon} alt={icon.name} />
        </SocialIcon>
      ))}
    </Root>
  );
};

Social.propTypes = {};

export default Social;

const Root = styled.div`
  display: flex;
  /* ${vw('margin-top', 20, 0, 0)} */
  ${vw('margin-top', 30, 60, 80)}

  & > * {
    ${vw('margin-right', 20, 24, 24)}
  }
  &:last-child {
    margin-right: 0;
  }
`;
const SocialIcon = styled.a`
  ${vw('margin-top', 0, -35, -40)}
  img {
    ${vw('max-width', 20, 24, 24)}
    ${vw('max-height', 20, 24, 24)}
  }
`;
