import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';

const Header = (props) => {
  const { active } = props;

  return (
    <Root active={active && 1}>
      <Band
        src={require('src/assets/images/hero-global/band_logo_header.svg')}
        alt="Band Logo"
      />
    </Root>
  );
};

Header.propTypes = {
  active: PropTypes.bool,
};

export default Header;

const Root = styled.header`
  width: 100%;
  ${vw('height', 40, 60, 90)}
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${({ theme }) => theme.color.black};

  /* vital parts for slide in animation of the header */
  top: 0;
  position: fixed;
  z-index: 1;
  transform: ${(props) =>
    props.active ? 'translateY(0%)' : 'translateY(-100%)'};
  transition: all 0.4s ease-in-out;
  /* vital parts for slide in animation of the header */
`;

const Band = styled.img`
  ${vw('width', 80, 120, 170)}
  ${vw('height', 16, 24, 34)}
`;
