import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import theme from 'src/styles/theme';

const ButtonDownload = ({ className, link }) => {
  return (
    <Root className={className} href={link} download>
      <img src={theme.icons.download.circle.default} alt="download icon" />
      <img src={theme.icons.download.circle.hover} alt="download icon" />
    </Root>
  );
};

export default ButtonDownload;

const Root = styled.a`
  position: relative;
  ${vw('width', 48)}
  ${vw('height', 48)}

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: ${(props) => props.theme.transition};

    &:first-child {
      opacity: 1;
    }

    &:last-child {
      opacity: 0;
    }
  }

  &:hover {
    cursor: pointer;
    transition: ${(props) => props.theme.transition};

    img {
      &:first-child {
        opacity: 0;
      }

      &:last-child {
        opacity: 1;
      }
    }
  }
`;
