import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';

import { FloorplanText } from 'src/styles/FloorplanText.styled';

const FloorplanSize = (props) => {
  const { interior, exterior } = props;

  return (
    <Root>
      <SizeWrapper>
        {interior && (
          <TextBlock>
            <FloorplanText extra>Interior</FloorplanText>
            <FloorplanText extra>{interior} SQFT</FloorplanText>
          </TextBlock>
        )}
        {/* {exterior && (
          <TextBlock>
            <FloorplanText>Exterior</FloorplanText>
            <FloorplanText>{exterior} SQFT</FloorplanText>
          </TextBlock>
        )} */}
      </SizeWrapper>
    </Root>
  );
};

FloorplanSize.propTypes = {
  interior: PropTypes.string,
  exterior: PropTypes.string,
};

export default FloorplanSize;

const Root = styled.div`
  text-transform: uppercase;
  background-color: ${(props) => props.theme.color.white};

  ${vw('padding-left', 3.91, 3.91, 20)}
  ${vw('padding-right', 3.91, 3.91, 20)}
  ${vw('padding-bottom', 22.65, 22.65, 30)}
`;

const SizeWrapper = styled.div``;

const TextBlock = styled.div`
  display: flex;
  justify-content: space-between;
  ${vw('margin-bottom', 3, 3, 7)}
`;
