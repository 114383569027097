import React from 'react';
import { useState } from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';

const MobileOverlay = ({ overlayActive, setOverlayActive, link }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(link);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Root overlayActive={overlayActive}>
      <Overlay>
        <Text>
          <a href={link} download>
            <Option className="first">
              <img
                src={
                  require('src/assets/images/arris/icons/download_mobile.svg')
                    .default
                }
                alt="download"
              />
              <h5 className="uppercase dark">Download Video</h5>
            </Option>
          </a>
        </Text>
      </Overlay>

      <Background onClick={() => setOverlayActive(false)} />
    </Root>
  );
};

export default MobileOverlay;

const Root = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);

  opacity: ${(props) => (props.overlayActive ? 1 : 0)};
  pointer-events: ${(props) => (props.overlayActive ? 'all' : 'none')};
  transition: ${(props) => props.theme.transition};

  .first {
    color: ${(props) => props.theme.color.black};
    img {
      ${vw('margin-right', 14.69)}
    }
  }

  .second {
    ${vw('margin-top', 13)}
    img {
      ${vw('margin-right', 14.69)}
    }
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${(props) => props.theme.color.black};
  opacity: 0.2;
`;

const Overlay = styled.div`
  position: absolute;
  z-index: 3;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  ${vw('width', 204)}
  ${vw('height', 84)}

  display: flex;
  align-items: center;
  ${vw('padding-left', 22)}

  background-color: ${(props) => props.theme.color.white};
  color: ${(props) => props.theme.color.black};
  ${vw('border-radius', 8)}
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;

  & > div:first-child {
    ${vw('margin-bottom', 10)}
  }
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
