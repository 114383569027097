import React from 'react';
// import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';

const Disclaimer = (props) => {
  return (
    <Root>
      <ParagraphBlock>
        <p>
          All illustrations reflect the artist’s interpretations only. Developer
          reserves the right to make changes and modifications to the
          information contained herein without prior notice. This is not an
          offering for sale. No such offer can be made without a disclosure
          statement E.&O.E.
        </p>
        <br />
        <p>
          © 2023 Bosa Development. All Rights Reserved.{' '}
          <a href="https://bamdigital.com" target="_blank">
            Website by Bam Digital
          </a>
        </p>
      </ParagraphBlock>
    </Root>
  );
};

Disclaimer.propTypes = {};

export default Disclaimer;

const Root = styled.div`
  font-family: 'Lato', sans-serif;
  font-weight: regular;
  ${vw('font-size', 11, 13, 13)}
  ${vw('line-height', 13.2, 15.6, 15.6)}
  color: ${({ theme }) => theme.color.white};
`;

const ParagraphBlock = styled.div`
  ${vw('width', 280, 580, 1482)}

  a {
    color: ${({ theme }) => theme.color.white};
    text-decoration: underline;
  }
`;
