export default {
  'marketing-assets': [
    {
      file: '/marketing_material/marketing_assets/ArrisBrochure_FA.pdf',
      thumbnail: require('src/assets/images/arris/marketing_material/marketin_assets/brochure.png'),
    },
    {
      thumbnail: require('src/assets/images/arris/marketing_material/marketin_assets/why_calgary.png'),
      file: '/marketing_material/marketing_assets/Why Calgary_1.pdf',
    },
    {
      thumbnail: require('src/assets/images/arris/marketing_material/marketin_assets/4_reasons.png'),
      file: '/marketing_material/marketing_assets/4 reasons to invest.pdf',
    },
    {
      thumbnail: require('src/assets/images/arris/marketing_material/marketin_assets/landlord.png'),
      file: '/marketing_material/marketing_assets/Arris_LandlordComparison_F.pdf',
    },
  ],
  'video-assets': [
    {
      source: '/downloads/marketing_material/video/video-retail.mp4',
      download: '/downloads/marketing_material/video/video-retail.mp4',
    },
    {
      source: '/downloads/marketing_material/video/video-retail.mp4',
      download: '/downloads/marketing_material/video/video-retail.mp4',
      hidden: true,
    },
  ],
  'social-assets': {
    exterior: {
      images: [
        {
          image: require('src/assets/images/arris/gallery/Exterior/Exterior_1@2x.jpg'),
          file: '/marketing_material/Exterior/Exterior_1@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Exterior/Exterior_2@2x.jpg'),
          file: '/marketing_material/Exterior/Exterior_2@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Exterior/Exterior_3@2x.jpg'),
          file: '/marketing_material/Exterior/Exterior_3@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Exterior/Exterior_4@2x.jpg'),
          file: '/marketing_material/Exterior/Exterior_4@2x.jpg',
        },
      ],
    },
    'interior (2-bed)': {
      images: [
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed/interior-2b-1@1.5x.png'),
          file: '/marketing_material/interior-2-bed/interior-2b-1.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed/interior-2b-2@1.5x.png'),
          file: '/marketing_material/interior-2-bed/interior-2b-2.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed/interior-2b-3@1.5x.png'),
          file: '/marketing_material/interior-2-bed/interior-2b-3.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed/interior-2b-4@1.5x.png'),
          file: '/marketing_material/interior-2-bed/interior-2b-4.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed/interior-2b-5@1.5x.png'),
          file: '/marketing_material/interior-2-bed/interior-2b-5.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed/interior-2b-6@1.5x.png'),
          file: '/marketing_material/interior-2-bed/interior-2b-6.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed/interior-2b-7@1.5x.png'),
          file: '/marketing_material/interior-2-bed/interior-2b-7.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed/interior-2b-8@1.5x.png'),
          file: '/marketing_material/interior-2-bed/interior-2b-8.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed/interior-2b-9@1.5x.png'),
          file: '/marketing_material/interior-2-bed/interior-2b-9.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed/interior-2b-10@1.5x.png'),
          file: '/marketing_material/interior-2-bed/interior-2b-10.jpg',
        },
      ],
    },
    'interior (2-bed+den)': {
      images: [
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed-den/interior-2b-den-1@1.5x.png'),
          file: '/marketing_material/interior-2-bed-den/interior-2b-den-1.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed-den/interior-2b-den-2@1.5x.png'),
          file: '/marketing_material/interior-2-bed-den/interior-2b-den-2.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed-den/interior-2b-den-3@1.5x.png'),
          file: '/marketing_material/interior-2-bed-den/interior-2b-den-3.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed-den/interior-2b-den-4@1.5x.png'),
          file: '/marketing_material/interior-2-bed-den/interior-2b-den-4.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed-den/interior-2b-den-5@1.5x.png'),
          file: '/marketing_material/interior-2-bed-den/interior-2b-den-5.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed-den/interior-2b-den-6@1.5x.png'),
          file: '/marketing_material/interior-2-bed-den/interior-2b-den-6.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed-den/interior-2b-den-7@1.5x.png'),
          file: '/marketing_material/interior-2-bed-den/interior-2b-den-7.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed-den/interior-2b-den-8@1.5x.png'),
          file: '/marketing_material/interior-2-bed-den/interior-2b-den-8.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed-den/interior-2b-den-9@1.5x.png'),
          file: '/marketing_material/interior-2-bed-den/interior-2b-den-9.jpg',
        },
        {
          image: require('src/assets/images/arris/marketing_material/interior-2-bed-den/interior-2b-den-10@1.5x.png'),
          file: '/marketing_material/interior-2-bed-den/interior-2b-den-10.jpg',
        },
      ],
    },
    amenities: {
      images: [
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_1@2x.jpg'),
          file: '/marketing_material/Amenities/Amenities_1@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_2@2x.jpg'),
          file: '/marketing_material/Amenities/Amenities_2@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_3@2x.jpg'),
          file: '/marketing_material/Amenities/Amenities_3@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_4@2x.jpg'),
          file: '/marketing_material/Amenities/Amenities_4@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_5@2x.jpg'),
          file: '/marketing_material/Amenities/Amenities_5@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_6@2x.jpg'),
          file: '/marketing_material/Amenities/Amenities_6@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_7@2x.jpg'),
          file: '/marketing_material/Amenities/Amenities_7@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_8@2x.jpg'),
          file: '/marketing_material/Amenities/Amenities_8@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_9@2x.jpg'),
          file: '/marketing_material/Amenities/Amenities_9@2x.jpg',
        },
        {
          image: require('src/assets/images/arris/gallery/Amenities/Amenities_10@2x.jpg'),
          file: '/marketing_material/Amenities/Amenities_10@2x.jpg',
        },
      ],
    },
  },
};
