import styled from 'styled-components';
import vw from 'src/styles/utils';

export const Body = styled.p`
  font-weight: 300;
  font-family: 'azo-sans-web';
  color: ${({ theme }) => theme.color.offWhite};
  ${(props) =>
    props.large ? vw('font-size', 18, 21, 21) : vw('font-size', 14, 16, 16)}
  ${(props) =>
    props.large ? vw('line-height', 36, 42, 42) : vw('line-height', 18, 24, 24)}
`;
