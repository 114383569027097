import React from 'react';
import styled from 'styled-components';

import vw from 'src/styles/utils';
import media from 'src/styles/media';

// This component dictates how many columns the grid will have in a responsive way.

const Grid = ({ columns = [], children, gap = [], title, marketing }) => {
  return (
    <Root className="grid-section">
      {title && <p className="strapline">{title}</p>}
      <Wrapper columns={columns} gap={gap} marketing={marketing}>
        {children}
      </Wrapper>
    </Root>
  );
};

export default Grid;

const Root = styled.div`
  .strapline {
    ${vw('margin-bottom', 32)}
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: inline-grid;
  ${({ columns }) => `grid-template-columns: repeat(${columns[0]}, 1fr);`}

  ${({ gap }) => gap && vw('gap', gap[0], gap[1], gap[2])}

  ${({ marketing }) => marketing && vw('row-gap', 40, 56, 75)}

  @media ${media.tablet} {
    ${({ columns }) => `grid-template-columns: repeat(${columns[1]}, 1fr);`}

    ${({ marketing }) => !marketing && 'grid-auto-rows: 1fr;'}
  }
  @media ${media.desktop} {
    ${({ columns }) => `grid-template-columns: repeat(${columns[2]}, 1fr);`}
  }
`;
