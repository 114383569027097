import styled from 'styled-components';
import vw from 'src/styles/utils';

export const FloorplanText = styled.p`
  text-transform: uppercase;
  font-family: 'azo-sans-web';
  font-weight: 400;
  ${vw('font-size', 15, 15, 15)}
  ${vw('line-height', 18, 18, 18)}
  ${vw('letter-spacing', 1.92, 1.92, 1.92)}
  color: ${({ theme }) => theme.color.black};
  opacity: ${({ extra }) => (extra ? 1 : 0)};
`;
