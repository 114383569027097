import React from 'react';
import styled from 'styled-components';

const HoverSwitch = ({ className, images }) => {
  return (
    <Root className={className}>
      <img src={images.default} alt="icon" />
      <img src={images.hover} alt="icon" />
    </Root>
  );
};

export default HoverSwitch;

const Root = styled.div`
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
    transition: ${(props) => props.theme.transition};
  }

  img:first-child {
    opacity: 1;
  }

  img:last-child {
    opacity: 0;
  }
`;
