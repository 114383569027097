import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import vw from 'src/styles/utils';

import { Body } from 'src/styles/Body.styled';

const Tag = (props) => {
  const { children } = props;

  return <Root large>{children}</Root>;
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tag;

const Root = styled(Body)`
  ${vw('padding-bottom', 4, 4, 4)}
  ${vw('margin-bottom', 4, 4, 4)}
  border-bottom: 1px solid ${({ theme }) => theme.color.offWhiteHalf};
`;
