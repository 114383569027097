export default {
  color: {
    black: '#262262',
    grey: '#333132',
    offWhite: '#F9F9F9',
    offWhiteHalf: '#F9F9F931',
    white: '#FFFFFF',
  },
  transition: '0.3s ease',
  icons: {
    download: {
      circle: {
        default: require('src/assets/images/arris/icons/download_icon.svg'),
        hover: require('src/assets/images/arris/icons/download_icon_hover.svg'),
      },
    },
    play: {
      default: require('src/assets/images/arris/icons/play_icon.svg'),
      hover: require('src/assets/images/arris/icons/play_icon_hover.svg'),
    },
  },
};
