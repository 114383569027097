export default [
  {
    unit: 'D3',
    no: '1802',
    tour: 'https://youriguide.com/2102_530_3_st_se_calgary_ab ',
    thumbnail: require('src/assets/images/arris/tour-icon/tour-d3@1.png'),
    bed: 2,
    bath: 2,
    den: true,
    interior: '1,276',
  },
  {
    unit: 'D2',
    no: '1708',
    tour: 'https://youriguide.com/2108_530_3_st_se_calgary_ab ',
    thumbnail: require('src/assets/images/arris/tour-icon/tour-d2@1.png'),
    bed: 2,
    bath: 2,
    den: true,
    interior: '1,249',
  },
  {
    unit: 'C4',
    no: '1704',
    tour: 'https://youriguide.com/2104_530_3_st_se_calgary_ab ',
    thumbnail: require('src/assets/images/arris/tour-icon/tour-c4@1.png'),
    bed: 2,
    bath: 2,
    interior: '1,194',
  },
  {
    unit: 'C3',
    no: '1806',
    tour: 'https://youriguide.com/2106_530_3_st_se_calgary_ab ',
    thumbnail: require('src/assets/images/arris/tour-icon/tour-c3@1.png'),
    bed: 2,
    bath: 2,
    interior: '1,109',
  },
  {
    unit: 'C2',
    no: '1705',
    tour: 'https://youriguide.com/2205_530_3_st_se_calgary_ab ',
    thumbnail: require('src/assets/images/arris/tour-icon/tour-c2@1.png'),
    bed: 2,
    bath: 2,
    interior: '1,081',
  },
  {
    unit: 'C1',
    no: '1803',
    tour: 'https://youriguide.com/2203_530_3_st_se_calgary_ab ',
    thumbnail: require('src/assets/images/arris/tour-icon/tour-c1@1.png'),
    bed: 2,
    bath: 2,
    interior: '968',
  },
  {
    unit: '',
    no: '1803',
    tour: 'https://youriguide.com/3008_530_3_st_se_calgary_ab',
    thumbnail: require('src/assets/images/arris/tour-icon/tour-display1@1.png'),
    bed: 2,
    bath: 2,
    interior: '1163',
  },
  {
    unit: '',
    no: '1803',
    tour: 'https://youriguide.com/3003_530_3_st_se_calgary_ab',
    thumbnail: require('src/assets/images/arris/tour-icon/tour-display2@1.png'),
    bed: 2,
    bath: 2,
    interior: '872',
  },
];
