import styled from 'styled-components';
import vw from 'src/styles/utils';
import media from 'src/styles/media';

export const SectionTitle = styled.h1`
  color: ${({ theme, dark }) =>
    dark ? theme.color.black : theme.color.offWhite};
  font-weight: 700;
  font-family: 'NarzissRegular';
  transition: 0.3s ease;
  ${vw('margin-bottom', 40, 0, 0)}
  text-transform: uppercase;
  ${vw('font-size', 24, 48, 64)}
  ${vw('line-height', 29, 58, 0)}
  ${vw('letter-spacing', 1.92, 1.92, 1.92)}

  @media ${media.tablet} {
    position: absolute;
    writing-mode: vertical-rl;
    ${vw('top', 0, 75, 120)}

    ${vw('left', 0, 90, 100)}
    font-weight: 300;
    transform: scale(-1);
    transform-origin: left;
  }
`;
