import styled from 'styled-components';
import vw from 'src/styles/utils';

export const H1 = styled.h1`
  color: ${({ theme }) => theme.color.offWhite};
  font-family: 'Gangster Grotesk';
  font-weight: 300;

  ${vw('font-size', 24, 36, 36)}
  ${vw('line-height', 28.8, 43.2, 43.2)}
  ${vw('letter-spacing', 1.92, 1.92, 1.92)}
  ${vw('margin-bottom', 20, 40, 40)}
`;
