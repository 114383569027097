import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SectionTitle } from 'src/styles/SectionTitle.styled';
import { Section } from 'src/styles/Section.styled';
import IconButton from 'src/components/IconButton';
import ImageFrame from 'src/components/ImageFrame';
import Grid from 'src/components/Grid';
import Video from 'src/pages/Home/sections/GuidedVideoTours/elements/Video';
import vw, { vwMobile, vwTablet, vwDesktop } from 'src/styles/utils';
import media from 'src/styles/media';

import marketingMaterial from '../../../data/marketingMaterial';

import { SubHeader } from 'src/styles/SubHeader.styled';

const MarketingMaterial = ({ className }) => {
  return (
    <Section white>
      <CustomSectionTitle dark>Marketing Material</CustomSectionTitle>
      <ButtonWrapper>
        <IconButton
          socialmedia
          darkBtn
          href={`/downloads/marketing_material.zip`}
        >
          Download Gallery Assets
        </IconButton>
      </ButtonWrapper>
      {/* Marketing assets */}
      <SectionWrapper>
        <CustomSubHeader dark>Marketing Assets</CustomSubHeader>
        <GalleryWrapper>
          {marketingMaterial['marketing-assets'].map((item, index) => (
            <ImageFrame
              dark
              key={index}
              image={item.image}
              thumbnail={item.thumbnail}
              fileName={`${item.file}`}
              special
            />
          ))}
        </GalleryWrapper>
      </SectionWrapper>
      <SectionWrapper>
        <CustomSubHeader dark>Video Assets</CustomSubHeader>
        <GalleryWrapper>
          <Grid
            columns={[
              1,
              1,
              marketingMaterial['video-assets'].length > 1 ? 2 : 1,
            ]}
            gap={[
              20,
              40,
              marketingMaterial['video-assets'].length > 1 ? 40 : 0,
            ]}
          >
            {marketingMaterial['video-assets'].map((item, index) => {
              return item.hidden ? (
                <></>
              ) : (
                <Video
                  key={index}
                  multiple={
                    marketingMaterial['video-assets'].length > 1 ? true : false
                  }
                  data={item}
                />
              );
            })}
          </Grid>
        </GalleryWrapper>
      </SectionWrapper>
      {/* SOCIAL ASSETS / EXTERIOR */}
      <SectionWrapper>
        <CustomSubHeader dark>Exterior</CustomSubHeader>
        <GalleryWrapper>
          {marketingMaterial['social-assets'].exterior.images.map(
            (item, index) => (
              <ImageFrame
                dark
                key={index}
                image={item.image}
                fileName={`${item.file}`}
              />
            )
          )}
        </GalleryWrapper>
      </SectionWrapper>
      {/* SOCIAL ASSETS / INTERIOR (2-bed) */}
      <SectionWrapper>
        <CustomSubHeader dark>SOCIAL ASSETS / INTERIOR (2-bed)</CustomSubHeader>
        <GalleryWrapper>
          {marketingMaterial['social-assets']['interior (2-bed)'].images.map(
            (item, index) => (
              <ImageFrame
                dark
                key={index}
                image={item.image}
                fileName={`${item.file}`}
              />
            )
          )}
        </GalleryWrapper>
      </SectionWrapper>
      {/* SOCIAL ASSETS / INTERIOR (2-bed+den) */}
      <SectionWrapper>
        <CustomSubHeader dark>
          SOCIAL ASSETS / INTERIOR (2-bed+den)
        </CustomSubHeader>
        <GalleryWrapper>
          {marketingMaterial['social-assets'][
            'interior (2-bed+den)'
          ].images.map((item, index) => (
            <ImageFrame
              dark
              key={index}
              image={item.image}
              fileName={`${item.file}`}
            />
          ))}
        </GalleryWrapper>
      </SectionWrapper>
      {/* SOCIAL ASSETS / Amenities */}
      <SectionWrapper>
        <CustomSubHeader dark>Amenities</CustomSubHeader>
        <GalleryWrapper>
          {marketingMaterial['social-assets'].amenities.images.map(
            (item, index) => (
              <ImageFrame
                dark
                key={index}
                image={item.image}
                fileName={`${item.file}`}
              />
            )
          )}
        </GalleryWrapper>
      </SectionWrapper>
      {/* SOCIAL ASSETS / WHY INVEST? */}
    </Section>
  );
};

MarketingMaterial.propTypes = {
  className: PropTypes.string,
};

export default MarketingMaterial;

const Root = styled.div``;

const CustomSectionTitle = styled(SectionTitle)`
  /* ${vw('top', -20, 208, 325)}
  ${vw('left', -200, -105, -80)} */
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  /* align-items: flex-start; */
  /* ${vw('margin-top', 120)} */
  ${vw('margin-bottom', 40, 60, 120)}
  @media ${media.tablet} {
    /* justify-content: flex-end; */
    justify-content: space-between;
  }

  @media ${media.desktop} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
  }
`;

const SectionWrapper = styled.div`
  & + & {
    ${vw('margin-top', 40, 80, 120)}
  }
`;

const CustomSubHeader = styled(SubHeader)`
  ${vw('margin-bottom', 10, 20, 40)}
  ${vw('margin-top', 40, 80, 0)}
`;

const GalleryWrapper = styled.div`
  ${vw('margin-left', 0, -10, -20)}

  display: inline-grid;
  grid-template-columns: repeat(2, ${vwMobile(134)});
  ${vw('grid-column-gap', 12, 20, 30)}
  ${vw('grid-row-gap', 20, 20, 30)}

  @media ${media.tablet} {
    grid-template-columns: repeat(3, ${vwTablet(180)});
  }

  @media ${media.desktop} {
    grid-template-columns: repeat(4, ${vwDesktop(348)});
  }
`;
